<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  {{ appName }}
                </div>
                <div
                  v-if="socialLoginEnabled"
                  class="mt-4"
                >
                  <v-btn
                    icon
                    class="mx-2"
                  >
                    <v-icon>
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="mx-2"
                  >
                    <v-icon>
                      mdi-twitter
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="mx-2"
                  >
                    <v-icon>
                      mdi-google-plus
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>

            <v-card-text class="text-center">
              <div class="text-center font-weight-light">
                {{ $t('login.title') }}
              </div>

              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    :name="$t('email').toLowerCase()"
                  >
                    <v-text-field
                      v-model="form.email"
                      :error-messages="errors"
                      color="primary"
                      :placeholder="$t('email')"
                      prepend-icon="mdi-email"
                      validate-on-blur
                    />
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('password').toLowerCase()"
                  >
                    <v-text-field
                      v-model="form.password"
                      :error-messages="errors"
                      class="mb-8"
                      color="primary"
                      type="password"
                      :placeholder="$t('password')"
                      prepend-icon="mdi-lock-outline"
                      validate-on-blur
                    />
                  </validation-provider>

                  <div class="d-flex justify-space-around mb-6">
                    <router-link
                      v-if="accountCreationEnabled"
                      to="/register"
                      class="font-weight-bold text-decoration-none"
                    >
                      {{ $t('login.createAccount') }}
                    </router-link>
                    <!--<router-link
                      to="/forgot-password"
                      class="font-weight-bold text-decoration-none"
                    >
                      {{ $t('login.forgotPassword') }}
                    </router-link>-->
                  </div>

                  <SubmitButton
                    :label="$t('login.title')"
                    type="submit"
                  />
                </form>
              </validation-observer>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import serverError from '../mixins/serverError';
  export default {
    name: 'LoginView',

    mixins: [serverError],

    data: () => ({
      socials: [
        {
          href: '#',
          icon: 'mdi-facebook',
        },
        {
          href: '#',
          icon: 'mdi-twitter',
        },
        {
          href: '#',
          icon: 'mdi-github',
        },
      ],
      form: {
        email: null,
        password: null,
      },
    }),

    computed: {
      ...mapGetters({
        accountCreationEnabled: 'app/accountCreationEnabled',
        socialLoginEnabled: 'app/socialLoginEnabled',
        processing: 'global/getProcessing',
        appName: 'app/appName',
      }),
    },

    methods: {
      submit () {
        const { email, password } = this.form
        const payload = { email, password };
        this.$store.dispatch('auth/login', payload)
          .then(() => {
            this.$toasted.success(this.$t('successful_login'));
            this.$router.push('/');
          })
          .catch(error => this.displayErrors(error));
      },
    },
  }
</script>
